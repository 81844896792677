import { useContext, useEffect, useState } from 'react'
import UserContext from '../../dataContext'
import Spinner from '../../Components/Spinner'
import "../../assets/scss/Billing/products.scss"
import { BsCloudDownloadFill, BsCloudUploadFill, BsFillPencilFill, BsXLg } from 'react-icons/bs'
import PopUp from './Common/PopUp/PopUp'
import { Parser } from 'json2csv';
import ProductTable from './Common/PopUp/Products/ProductTable'

export default function Products() {
    const userContext = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const [activeSidebar, setActiveSidebar] = useState(false)
    const [sidebarContent, setSidebarContent] = useState(null)
    const [search, setSearch] = useState("")
    const [showPopUp, setShowPopUp] = useState(false)
    const [action, setAction] = useState("")
    const [popUpContent, setPopUpContent] = useState("")
    const [inactiveProducts, setInactiveProducts] = useState([])
    const [activeProducts, setActiveProducts] = useState([])


    function convertToCSV(data) {
        const fields = [
            { label: 'Code', value: 'code' },
            { label: 'Libellé', value: 'label' },
            { label: 'Famille', value: 'family' },
            { label: 'Type', value: 'type' },
            { label: 'Prix', value: 'price' },
            { label: 'Prix Total', value: 'priceFull' },
            { label: 'Quantité', value: 'quantity' },
            { label: 'TVA', value: 'taxes' },
            { label: 'Compte comptable', value: 'account' },
            { label: 'Description', value: 'description' },
            { label: 'État', value: 'state' }
        ];
        const json2csvParser = new Parser({ fields, withBOM: true });
        return json2csvParser.parse(data);
    }

    function downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    useEffect(() => {
        userContext.apiReducer("getProducts").then(productsList => {
            setLoading(false)
            productsList = sortProducts(productsList, "abc", "code")
            setProducts(productsList)
        })
    }, [userContext])

    // When products change then update activeProducts and inactiveProducts
    useEffect(() => {
        const activeProducts = products.filter(product => product.state === "ACTIF")
        const inactiveProducts = products.filter(product => product.state === "INACTIF")
        setActiveProducts(activeProducts)
        setInactiveProducts(inactiveProducts)
    }, [products])

    const handleProductUpdate = (updatedProduct) => {
        setProducts(prevProducts => {
            return prevProducts.map(product =>
                product.id === updatedProduct.id ? updatedProduct : product
            )
        })
    }

    function sortProducts(list, method, key) {
        switch (method) {
            case "abc":
                if (key) {
                    list.sort((a, b) => {
                        if (a[key] < b[key]) return -1;
                        if (a[key] > b[key]) return 1;
                        return 0;
                    });
                }
                break;

            default:
                break;
        }
        return list
    }

    function capitalizeText(text) {
        return text.toLowerCase().replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    if (loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Produits</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement des produits...</h2>
            </div>
        )
    }
    else if (!loading) {
        return (
            <div className={"products col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Produits</h1>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="search-and-stuff d-flex justify-content-between align-items-center">
                            <button className="btn btn-primary ms-2" onClick={(e) => {
                                e.preventDefault();
                                const csv = convertToCSV(products);
                                downloadCSV(csv, 'products.csv');
                            }}><BsCloudDownloadFill /></button>
                            <button className="btn btn-primary ms-2" onClick={(e) => {
                                e.preventDefault();
                                setShowPopUp(true)
                                setAction("uploadProducts")
                                setPopUpContent({ what: { type: "Produits", title: "Importer des produits" }, thing: {} })
                            }}><BsCloudUploadFill /></button>
                            <div className="search">
                                <input type="text" id="formControlLg" className="form-control form-control-lg" placeholder='Rechercher un produit...' onInput={(e) => setSearch(e.target.value)} />
                            </div>
                            <button className="btn btn-primary ms-2" onClick={(e) => {
                                e.preventDefault();
                                setShowPopUp(true)
                                setAction("addProduct")
                                setPopUpContent({ what: { type: "Produits", title: "Ajouter un produit" }, thing: { new: true } })
                            }}>+</button>
                        </div>
                        <ProductTable
                            products={activeProducts}
                            search={search}
                            setActiveSidebar={setActiveSidebar}
                            setSidebarContent={setSidebarContent}
                            capitalizeText={capitalizeText}
                            setShowPopUp={setShowPopUp}
                            setAction={setAction}
                            setPopUpContent={setPopUpContent}
                        />
                        <div className={"row justify-content-between"}>
                            <div>
                                <h1 className="blue fw-bold text-center mt-5">Produits inactifs</h1>
                            </div>
                        </div>
                        <ProductTable
                            products={inactiveProducts}
                            search={search}
                            setActiveSidebar={setActiveSidebar}
                            setSidebarContent={setSidebarContent}
                            capitalizeText={capitalizeText}
                            setShowPopUp={setShowPopUp}
                            setAction={setAction}
                            setPopUpContent={setPopUpContent}
                        />
                    </div>
                </div>
                <div id='product-sidebar' className={'col' + (activeSidebar ? " active" : "")}>
                    <div className='header'>
                        <button className="close-sidebar" onClick={() => setActiveSidebar(false)}><BsXLg /></button>
                        <button className="edit-sidebar" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowPopUp(true)
                            setAction("modifyProduct")
                            const product = { ...sidebarContent.thing }
                            setPopUpContent({ what: { type: "Produits", title: "Modifier un produit" }, thing: { product } })
                        }}><BsFillPencilFill /></button>


                        {sidebarContent ? <h2>{sidebarContent.what}</h2> : ""}
                    </div>
                    {sidebarContent ?
                        <div className='content'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h2>{sidebarContent.thing.code + " - " + sidebarContent.thing.label}</h2>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul>
                                        <li><span>Description : </span>{sidebarContent.thing.description}</li>
                                        <li><span>Type :</span> {capitalizeText(sidebarContent.thing.type)}</li>
                                        <li><span>Famille :</span> {sidebarContent.thing.family}</li>
                                        <li><span>Prix HT :</span> {sidebarContent.thing.price}€</li>
                                        <li><span>Prix TTC :</span> {sidebarContent.thing.priceFull}€</li>
                                        <li><span>TVA :</span> {sidebarContent.thing.taxes}% </li>
                                        <li><span>Unité :</span> {sidebarContent.thing.quantity}</li>
                                        <li><span>Compte comptable :</span> {sidebarContent.thing.account}</li>
                                        <li><span>Actif :</span> {sidebarContent.thing.state === "ACTIF" ? "Oui" : "Non"}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        : ""}
                </div>
                <PopUp showPopUp={showPopUp} setShowPopUp={setShowPopUp} action={action} popUpContent={popUpContent} type={"product"} setProducts={setProducts} handleProductUpdate={handleProductUpdate} />
            </div>
        )
    }
}