import { useContext, useEffect, useState, useCallback } from "react";
import UserContext from "../../../../../dataContext";
import Spinner from "../../../../../Components/Spinner";

export default function ProductPopUpForm({ action, thing, reset, setIsSubmitting, setShowPopUp, setProducts, onClose }) {
    const userContext = useContext(UserContext)
    const product = thing?.product
    const [code, setCode] = useState("")
    const [label, setLabel] = useState("")
    const [family, setFamily] = useState("Particuliers")
    const [type, setType] = useState("")
    const [price, setPrice] = useState("")
    const [priceFull, setPriceFull] = useState("")
    const [quantity, setQuantity] = useState("")
    const [taxes, setTaxes] = useState("")
    const [account, setAccount] = useState("")
    const [description, setDescription] = useState("")
    const [state, setState] = useState("")

    const resetFormFields = useCallback(() => {
        setCode(product?.code || "");
        setLabel(product?.label || "");
        setFamily(product?.family || "");
        setType(product?.type || "");
        setPrice(product?.price || "");
        setPriceFull(product?.priceFull || "");
        setQuantity(product?.quantity || "");
        setTaxes(product?.taxes || "");
        setAccount(product?.account || "");
        setDescription(product?.description || "");
        setState(product?.state || "");
    }, [product]);

    useEffect(() => {
        if (reset) reset(resetFormFields);
    }, [reset, resetFormFields]);

    useEffect(() => {
        if (action === "addProduct" || thing.new) {
            const fetchAndSetCode = async () => {
                const code = await userContext.apiReducer("getProductCode");
                console.log("code", code);
                setCode(code);
            };
            fetchAndSetCode();
        } else if (action === "modifyProduct") {
            resetFormFields();
        }
    }, [action, product, thing, resetFormFields, userContext]);

    useEffect(() => {
        if (thing?.new) {
            resetFormFields();
        }
    }, [thing, resetFormFields]);

    useEffect(() => {
        const numericPrice = parseFloat(price);
        const numericTaxes = parseFloat(taxes);
        if (!isNaN(numericPrice) && !isNaN(numericTaxes)) {
            const calculatedPriceFull = (numericPrice + (numericPrice * numericTaxes) / 100).toFixed(2);
            setPriceFull(calculatedPriceFull);
        }
    }, [price, taxes]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            code,
            label,
            family,
            type,
            price,
            priceFull,
            quantity,
            taxes,
            account,
            description,
            state
        };
        if (action === "addProduct") {
            userContext.apiReducer("addProduct", data)
                .then((response) => {
                    console.log("response", response)
                    setIsSubmitting(false)
                    setShowPopUp(false)
                    setProducts((prevProducts) => [...prevProducts, response])
                    onClose(response)
                })
                .catch((error) => {
                    console.log("error", error);
                    setIsSubmitting(false);
                });
        } else if (action === "modifyProduct") {
            userContext.apiReducer("modifyProduct", { ...data })
                .then((response) => {
                    console.log("response", response);
                    setIsSubmitting(false);
                    setShowPopUp(false);
                    setProducts((prevProducts) => prevProducts.map((product) => product.code === code ? response : product));
                })
                .catch((error) => {
                    console.log("error", error);
                    setIsSubmitting(false);
                });
        }
    };


    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3 w-25 position-relative">
                                <label htmlFor="code" className="form-label required">Code :</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    id="code"
                                    value={code.toString().padStart(8, '0')}
                                    onChange={(e) => setCode(e.target.value)}
                                    disabled={!code}
                                />
                                {!code && (
                                    <div className=" position-absolute top-50 end-0 translate-middle-y" role="status">
                                        <Spinner strokeWidth={6} width={20} color={"#003952"} />
                                    </div>
                                )}
                            </div>
                            <div className="me-3">
                                <label htmlFor="label" className="form-label">Libellé :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="label"
                                    value={label}
                                    onChange={(e) => setLabel(e.target.value)} />
                            </div>
                            <div className="me-3 w-25">
                                <label htmlFor="family" className="form-label">Famille :</label>
                                <select
                                    className="form-control"
                                    id="family"
                                    value={family || "Particuliers"}
                                    onChange={(e) => setFamily(e.target.value)}
                                    required>
                                    <option selected value="Particuliers">Particuliers</option>
                                    <option value="Gestion Locative">Gestion Locative</option>
                                    <option value="Professionnels">Professionnels</option>
                                    <option value="Propriétaire">Propriétaire</option>
                                    <option value="Interne">Interne</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="type" className="form-label">Type :</label>
                                <select
                                    className="form-control"
                                    id="type"
                                    value={type || "Produit"}
                                    onChange={(e) => setType(e.target.value)}
                                    required>
                                    <option selected value="Produit">Produit</option>
                                    <option value="Service">Service</option>
                                </select>
                            </div>
                            <div className="me-3">
                                <label htmlFor="price" className="form-label">Prix HT :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="price"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    onBlur={() => {
                                        const numericValue = parseFloat(price);
                                        setPrice(isNaN(numericValue) ? '' : numericValue.toString());
                                    }} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="priceFull" className="form-label">Prix TTC :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="priceFull"
                                    value={priceFull}
                                    readOnly
                                    onChange={(e) => setPriceFull(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="quantity" className="form-label">Quantité:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="quantity"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="taxes" className="form-label">Taxes (%) :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="taxes"
                                    value={taxes}
                                    onChange={(e) => setTaxes(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="account" className="form-label">Compte comptable :</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="account"
                                    value={account}
                                    onChange={(e) => setAccount(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="state" className="form-label">État :</label>
                                <select
                                    className="form-control"
                                    id="state"
                                    value={state || "ACTIF"}
                                    onChange={(e) => setState(e.target.value)}
                                    required>
                                    <option selected value="ACTIF">ACTIF</option>
                                    <option value="INACTIF">INACTIF</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="description" className="form-label">Description :</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">Envoyer</button>
                </div>
            </form>
        </div>
    )
}
