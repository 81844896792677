import { useState, useRef } from "react";
import Spinner from "../../../Components/Spinner";
import { PopUp } from "../Contacts"; // Corrected import for default export

export default function ClientPopUp({ showClientPopUp, setShowClientPopUp, contacts, setClientId, setSiret, setAddress,
    setPayingMethod, setFullAddress, setFullSecondAddress, setBillingAddress, setIsTheSameAsFullAddress, setFullName, setClientMail, handleModif, userContext
}) {
    const [searchTerm, setSearchTerm] = useState("");
    const [showCreateClientPopUp, setShowCreateClientPopUp] = useState(false);
    const [popUpContent, setPopUpContent] = useState(null);
    // const [action, setAction] = useState("");
    const contactPopUp = useRef(null);

    // Updated handler to match Contacts.js PopUp format
    const handleCreateClientClick = () => {
        setPopUpContent({
            what: { type: "Contact", title: "Créer un contact" },
            thing: { object: {} }
        });
        setShowCreateClientPopUp(true);
    };

    // Create a function to handle setting all client data
    const handleClientSelection = (contact) => {
        if (contact.fullAddress?.roadAndNumber) {
            setFullAddress({
                roadAndNumber: contact.fullAddress.roadAndNumber,
                zipCode: contact.fullAddress.zipCode,
                city: contact.fullAddress.city,
                country: contact.fullAddress.country,
                complement: contact.fullAddress.complement,
            });
        }

        if (contact.fullSecondAddress?.roadAndNumber) {
            setFullSecondAddress({
                roadAndNumber: contact.fullSecondAddress.roadAndNumber,
                zipCode: contact.fullSecondAddress.zipCode,
                city: contact.fullSecondAddress.city,
                country: contact.fullSecondAddress.country,
                complement: contact.fullSecondAddress.complement,
            });
        }

        if (contact.billingAddress?.roadAndNumber) {
            setBillingAddress({
                roadAndNumber: contact.billingAddress.roadAndNumber,
                zipCode: contact.billingAddress.zipCode,
                city: contact.billingAddress.city,
                country: contact.billingAddress.country,
                complement: contact.billingAddress.complement,
                isTheSameAsFullAddress: contact.billingAddress.isTheSameAsFullAddress,
            });
        }

        setClientId(contact.clientId);
        setSiret(contact.siret);
        setPayingMethod(contact.paymentMethod);
        setIsTheSameAsFullAddress(contact.billingAddress?.isTheSameAsFullAddress);
        setFullName(
            contact.status !== "Personne Morale"
                ? `${contact.name} ${contact.lastName}`
                : contact.socialReason
        );
        setClientMail(contact.email);
        setShowClientPopUp(false);
    };

    const wrappedHandleModif = (newClient, oldClient, type) => {
        if (type === "addClient") {
            handleClientSelection(newClient);
        }
        handleModif(newClient, oldClient, type);
    };

    const filteredContacts = contacts.filter(contact =>
        contact?.clientId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact?.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (contacts.length === 0) return (
        <div className={'billing-popup' + (showClientPopUp ? " open" : "")} onClick={(e) => { if (e.target.classList.contains('billing-popup')) { e.target.classList.remove('open'); setShowClientPopUp(false); } }}>
            <div className="container">
                <h3>Clients</h3>
                <h2>Chargement des clients...</h2>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
            </div>
        </div>
    )
    return (
        <>
            <div ref={contactPopUp} className={'billing-popup' + (showClientPopUp ? " open" : "")} onClick={(e) => {
                if (e.target.classList.contains('billing-popup')) {
                    e.target.classList.remove('open');
                    setShowClientPopUp(false);
                }
            }}>
                <div className="container" onClick={(e) => e.stopPropagation()}>
                    <h3>Clients</h3>
                    <input
                        type="text"
                        placeholder="Rechercher un client..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control mb-3"
                    />
                    <button className="btn btn-secondary mt-2" onClick={handleCreateClientClick}>
                        + Ajouter un nouveau client
                    </button>

                    <table className="contactTable table-maxxing">
                        <thead>
                            <tr>
                                <th>
                                    <span>ID Client</span>
                                </th>
                                <th>
                                    <span>Nom</span>
                                </th>
                                <th>
                                    <span>Prénom</span>
                                </th>
                                <th>
                                    <span>Email</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredContacts.map((contact, index) => (
                                <tr
                                    key={index}
                                    onClick={() => {
                                        handleClientSelection(contact)
                                    }}
                                >
                                    <td>{contact.clientId}</td>
                                    {contact.status === "Personne morale" ? (
                                        <>
                                            <td>{contact.socialReason}</td>
                                            <td>{contact.formeJuridique}</td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{contact.lastName}</td>
                                            <td>{contact.name}</td>
                                        </>
                                    )}
                                    <td>{contact.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {showCreateClientPopUp && popUpContent && (
                <PopUp
                    showPopUp={showCreateClientPopUp}
                    setShowPopUp={setShowCreateClientPopUp}
                    popUpContent={popUpContent}
                    userContext={userContext}
                    handleModif={wrappedHandleModif}
                    apiActionOut={"addClient"}
                    setShowClientPopUp={setShowClientPopUp}
                    handleClientSelection={handleClientSelection}
                />
            )}
        </>
    );
}