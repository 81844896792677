import { useContext, useState } from 'react';
import * as XLSX from 'xlsx';
import UserContext from '../../../../../dataContext'

export default function ProductPopUpContent() {
    const [isLoading, setIsLoading] = useState(false)
    const userContext = useContext(UserContext)
    const [, setFile] = useState(null);

    return (
        <div className={"mx-auto p-2 mt-5"}>
            <div className={"row justify-content-between gap-2"}>
                <div className="row flex-column align-items-center text-center">
                    <h2 className="blue fw-bold">Facturation - produits</h2>
                    <p className="black"><strong>Envoyez un fichier .csv de la liste des produits pour la facturation</strong></p>
                    <p className="black"><strong>Le modèle a été pris de MEG, donc l'import des prouits depuis MEG est fortement conseillé.</strong></p>
                    <p className="black"><strong>Sur MEG : effectuer un export paramétrable -{'>'} Ajouter colonnes : Unité, Type, Etat.</strong></p>
                    <form className="row flex-column mt-4 w-auto" onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsLoading(true);
                        const file = e.target.products.files[0];
                        let reader = new FileReader();
                        let fileExtension = file.name.split('.').pop().toLowerCase();
                        reader.onload = function (event) {
                            let fileContent = event.target.result;
                            let csvString;

                            if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                                // Handle XLS/XLSX file
                                let workbook = XLSX.read(fileContent, { type: 'binary' });
                                let firstSheetName = workbook.SheetNames[0];
                                let worksheet = XLSX.utils.sheet_to_csv(workbook.Sheets[firstSheetName]);
                                csvString = worksheet;
                            } else if (file.type === 'text/csv') {
                                // Handle CSV file
                                csvString = fileContent;
                            } else {
                                console.error('Unsupported file type');
                                setIsLoading(false);
                                return;
                            }

                            // Manually parse CSV string to JSON
                            let lines = csvString.split('\n');
                            let headers = lines[0].split(',');
                            let parsedData = lines.slice(1).map(line => {
                                let values = line.split(',');
                                let obj = {};
                                headers.forEach((header, index) => {
                                    obj[header.trim()] = values[index].trim();
                                });
                                return obj;
                            });

                            // Convert JSON object to string
                            const csvJSONString = JSON.stringify(parsedData);
                            const fd = new FormData();
                            fd.append('products', csvJSONString);
                            userContext.apiReducer('createProductsFromCSV', fd, {})
                                .then((response) => {
                                    setIsLoading(false);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    setIsLoading(false);
                                });
                        };

                        if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                            reader.readAsArrayBuffer(file);
                        } else if (file.type === 'text/csv') {
                            reader.readAsText(file);
                        } else {
                            console.error('Unsupported file type');
                            setIsLoading(false);
                        }
                    }}>
                        <input type="file" accept=".csv, .xls" className="form-control-file" name="products" id="products" required onChange={(e) => {
                            setFile(e.target.files)
                        }} />
                        <button type="submit" className="btn btn-primary mt-4">{isLoading ? <div className="spinner-border text-light" role="status"></div> : 'Envoyer'}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}