import { BsFillPencilFill } from "react-icons/bs"

export default function ProductTable({ products, setActiveSidebar, setSidebarContent, search, setShowPopUp, setAction, setPopUpContent, capitalizeText }) {
    return (
        <table>
            <thead>
                <tr>
                    <th scope='col'>
                        <span>

                        </span>
                    </th>
                    <th scope='col'>
                        <span>
                            Code
                        </span>
                    </th>
                    <th scope='col'>
                        <span>
                            Libellé
                        </span>
                    </th>
                    <th scope='col'>
                        <span>
                            Type
                        </span>
                    </th>
                    <th scope='col'>
                        <span>
                            Famille
                        </span>
                    </th>
                    <th scope='col'>
                        <span>
                            Prix HT (€)
                        </span>
                    </th>
                    <th scope='col'>
                        <span>
                            Prix TTC (€)
                        </span>
                    </th>
                    <th scope='col'>
                        <span>
                            Unité
                        </span>
                    </th>
                    <th scope='col'>
                        <span>

                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {products?.map((product, index) => {
                    // console.log(product, "product")
                    if (!product.code) return null
                    else if (search && !product.label.toLowerCase().includes(search.toLowerCase())) return null
                    return (
                        <tr data-num-ligne="0" className="" key={index} onClick={() => {
                            setActiveSidebar(true)
                            setSidebarContent({ thing: product, what: "Produit" })
                        }} >
                            <td>
                                <div className="display-flex flex-align-items-center">
                                    <input type="checkbox" name={"j-select-changement-statut-" + index} className="rca-legacy j-select-changement-statut" data-id="15612346" />
                                </div>
                            </td>
                            <td data-label="Code" className="lesser " title="Afficher l'aperçu de l'article">
                                <span className="recherche-selected  j-dont-close-sliding-panel"></span>{product.code?.toString().padStart(8, '0')}
                            </td>
                            <td data-label="Libellé" className="full" title="Afficher l'aperçu de l'article">
                                <span className="recherche-selected "></span>{product.label}
                            </td>
                            <td data-label="Type" className="lesser capitalize-text" title="Afficher l'aperçu de l'article">
                                <span className="recherche-selected  j-dont-close-sliding-panel"></span>{capitalizeText(product.type)}
                            </td>
                            <td data-label="Famille" className="lesser" title="Afficher l'aperçu de l'article">
                                <span className="recherche-selected  j-dont-close-sliding-panel"></span>{product.family}</td>
                            <td data-label="Prix HT (€)" className="lesser text-right" title="Afficher l'aperçu de l'article">
                                {product.price}
                            </td>
                            <td data-label="Prix TTC (€)" className="lesser text-right" title="Afficher l'aperçu de l'article">
                                {product.priceFull}
                            </td>
                            <td data-label="Unité" className="lesser" title="Afficher l'aperçu de l'article">
                                <span className="recherche-selected  j-dont-close-sliding-panel"></span>
                            </td>
                            <td className="text-right width-1">
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowPopUp(true)
                                    setAction("modifyProduct")
                                    setPopUpContent({ what: { type: "Produits", title: "Modifier un produit" }, thing: { product } })
                                }}><BsFillPencilFill /></button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}